<template>
  <t-modal
    :name="id"
    :variant="`xl`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="false"
    :clickToClose="false"
    ref="popup"
  >
    <div v-if="!isMailSent" class=" px-5 py-10">
      <div class="flex flex-col items-center">
        <div class="w-full flex justify-center">
          <img
            src="@/assets/marketplace/open-email.svg"
            alt="open-email"
            class="w-auto h-20 "
          />
        </div>
        <p class="font-bold text-xl">Confirm update to your email</p>
      </div>
      <p class="text-gray-600 mt-6 text-sm text-center">
        Click <span class="font-bold text-gray-900">Send Email</span> below, and
        we'll send an email to
        <span class="font-bold text-gray-600">{{ email }}</span> with a link to
        confirm the update.
      </p>
    </div>
    <div v-else class=" px-5 py-10">
      <div class="">
        <h1
          class="mt-16 mb-5 text-lg font-bold text-xl md:text-2xl text-center"
          style="color: #505458"
        >
          Enter Verification Code
        </h1>
        <p class="text-gray-600 text-sm text-center">
          To complete your request, enter the verification code we sent to
          <span class="font-bold text-gray-900">{{ getSecretEmail }}</span>
        </p>
      </div>
      <div class="flex items-center justify-center">
        <div class="relative py-5">
          <loading
            v-if="isLoading"
            :active="isLoading"
            :height="25"
            :opacity="0.5"
            :color="`#000`"
            :loader="`bars`"
            :is-full-page="false"
            :class="`outline-none`"
          />
          <otp-input
            @on-complete="onCompleteOTPHandler"
            :digits="6"
            mode="separate"
          >
            <template #errorMessage> There is an error </template>
          </otp-input>
        </div>
      </div>
      <p class="text-center">
        Didn't get the code?
        <span
          class="text-blue-500 font-bold cursor-pointer"
          @click="executeAction('sendEmail')"
          >Resend</span
        >
      </p>
    </div>

    <section class="flex items-center py-4 px-4 mt-6 space-x-5 bg-gray-100">
      <anchor-button
        v-if="!isMailSent"
        :variant="confirmButtonVariant"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="executeAction('sendEmail')"
      >
        Send Email
      </anchor-button>

      <anchor-button
        v-if="isMailSent"
        :variant="confirmButtonVariant"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="executeAction('verifyEmail')"
      >
        Submit
      </anchor-button>

      <anchor-button
        :variant="cancelButtonVariant"
        :class="`w-1/2`"
        @click="executeAction('cancel')"
      >
        {{ cancelButtonText }}
      </anchor-button>
    </section>
  </t-modal>
</template>

<script>
import { TYPE, POSITION } from 'vue-toastification'
import OtpInput from 'otp-input-vue2'
import xMan from '@/utils/xMan'
export default {
  name: 'EmailChange',
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    OtpInput,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    sendEmailURL: {
      type: String,
      required: true,
    },
    verifyEmailURL: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: [String, Number],
      required: false,
      default: 'Cancel',
    },
    cancelButtonVariant: {
      type: String,
      required: false,
      default: 'primary',
      validaotor(val) {
        return [
          'primary',
          'secondary',
          'success',
          'success-alt',
          'warning',
          'warning-alt',
          'disabled',
        ].includes(val)
      },
    },
    confirmButtonVariant: {
      type: String,
      required: false,
      default: 'warning-alt',
      validaotor(val) {
        return [
          'primary',
          'secondary',
          'success',
          'success-alt',
          'warning',
          'warning-alt',
          'disabled',
        ].includes(val)
      },
    },
  },

  data: () => ({
    isLoading: false,
    isMailSent: false,
    otpCode: null,
  }),
  computed: {
    getSecretEmail() {
      return new xMan(this.email).asteriskify(7, 5)
    },
  },

  methods: {
    onCompleteOTPHandler(code) {
      this.otpCode = code
    },
    executeAction(type) {
      if (type === 'sendEmail' && this.isLoading === false) {
        this.isLoading = true
        const data = new FormData()
        data.append('email', this.email)
        this.$http
          .post(this.sendEmailURL, data)
          .then(() => {
            this.isMailSent = true
            this.$toast('Sent Mail Successfully', {
              position: POSITION.BOTTOM_RIGHT,
              type: TYPE.SUCCESS,
            })
          })
          .catch((err) => {
            console.log('sentEmailErr', { err })
            this.$toast('Failed to sent mail', {
              position: POSITION.BOTTOM_RIGHT,
              type: TYPE.ERROR,
            })
          })
          .finally(() => (this.isLoading = false))
      }

      if (type === 'verifyEmail') {
        if (String(this.otpCode).length === 6) {
          this.isLoading = true
          const data = new FormData()
          data.append('code', this.otpCode)
          this.$http
            .post(this.verifyEmailURL, data)
            .then(() => {
              this.$emit('success')
              this.$refs.popup.hide()
              this.$toast('Email has been changed successfully', {
                position: POSITION.BOTTOM_RIGHT,
                type: TYPE.SUCCESS,
              })
            })
            .catch((err) => {
              console.log('verifyEmailErr', { err })
              this.$toast(
                err.response.data.message
                  ? err.response.data.message
                  : 'Failed to verify email',
                {
                  position: POSITION.BOTTOM_RIGHT,
                  type: TYPE.ERROR,
                }
              )
            })
            .finally(() => {
              this.isLoading = false
              this.initData()
            })
        }
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.$refs.popup.hide()
        this.initData()
        this.$emit('cancel')
      }
    },
    initData() {
      this.isMailSent = false
      this.otpCode = null
    },
  },
}
</script>

<style lang="scss" scoped></style>
