<template lang="">
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <div class="flex justify-between items-center">
        <div class="font-bold text-22px text-oCharcoal">
          <span class="">
            Profile Info
          </span>
        </div>
      </div>
      <div :key="`profileInfo_${counter}`" class="mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in profileInfoSettings">
          <SmartAppInput
            v-if="item.variable === 'company_name' && profileInfo.company_name"
            :key="itemIndex"
            :rowId="`profileInfo_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
         
            :isEdit="item.isEdit"
            v-model="profileInfo[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
          <SmartAppInput
            v-if="item.variable === 'email'"
            :key="itemIndex"
            :rowId="`profileInfo_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
         
            :isEdit="item.isEdit"
            v-model="profileInfo[`${item.variable}`]"
            @save="onEmail"
          />
          <SmartAppInput
            v-if="item.variable !== 'company_name' && item.variable !== 'email'"
            :key="itemIndex"
            :rowId="`profileInfo_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
         
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="profileInfo[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
        </template>
      </div>
      <div class="flex justify-between items-center mt-10">
        <div>
          <div class="flex items-center">
            <i class="fas fa-lock text-blue-700 text-22px"></i>
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              Change password
            </div>
          </div> 
        </div>
        <div class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end margin">
          <div v-if="!isPasswordEdit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handlePassEdit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
        <div v-if="isPasswordEdit" class="flex ml-3 mt-3 mb-4">
          <anchor-button
            @click="handlePasswordCancel"
            text="cancel"
            width="6rem"
          />
          <anchor-button
            @click="handleSave"
            :isLoading="isChangePasswordLoading"
            :disabled="isSavePasswordButtonDisabled"
            text="Change Password"
            variant="success"
            width="8rem"
            class="ml-4"
          />
        </div>
      </div>

      <div class="mt-3 pt-5 border-t-2">
      <div class="grid grid-cols-12">
  <div class="col-span-12 md:col-span-3">
    <div class="label">
      <input-label text="Current Password" />
    </div>
  </div>
  <div class="col-span-12 md:col-span-6 ">
    <AppInput
      v-model="form.old_password"
      type="password"
      name="Current Password"
      placeholder="********"
    />
<p class="-mt-3 mb-2" style="line-height: 1.2; font-size: 12px;">
    Your new password must be at least 8 characters long and include a combination of uppercase and lowercase letters, numbers, and special characters. Ensure that your new password is different from your old one. Once you've filled in the required fields, click 'Change Password' to update your account credentials. If you encounter any issues or need assistance, please contact the system administrator.
</p>

  </div>
</div>


        <div class="grid grid-cols-12" v-if="isPasswordEdit">
          <div class="col-span-12 md:col-span-3">
            <div class="label">
              <input-label text="New Password" />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <AppInput
              v-model="form.new_password"
              type="password"
              name="New Password"
              placeholder=""
            />
          </div>
        </div>

        <div class="grid grid-cols-12" v-if="isPasswordEdit">
          <div class="col-span-12 md:col-span-3">
            <div class="label">
              <input-label text="Repeat Password" />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <AppInput
              v-model="repeat_new_password"
              type="password"
              name="Repeat Password"
              placeholder=""
            />
          </div>
        </div>
      </div>
    </div>
    <EmailChange
      :id="`email-change`"
      :email="profileInfo.email"
      :sendEmailURL="getSendEmailURL"
      :verifyEmailURL="getVerifyEmailURL"
      @cancel="profileInfo.email = currentProfileEmail"
    />
  </section>
</template>







<script>
import { EventBus } from '@/utils/EventBus'
import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import { useEndpoints } from '@/composables'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import EmailChange from '@/components/modals/EmailChange.vue'
import { TYPE, POSITION } from 'vue-toastification'
import xMan from '@/utils/xMan'

export default {
  name: 'ProfileSettings',
  components: {
    InputLabel,
    AnchorButton,
    SmartAppInput,
    EmailChange,
  },

  data() {
    return {
      isLoaded: false,
      currentSettingsGroup: null,
      isChangePasswordLoading: false,
      isPasswordEdit: false,
      counter: 0,
      profileInfo: {
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
      },
      currentProfileEmail: '',
      repeat_new_password: null,
      form: {
        new_password: null,
        old_password: null,
      },
      profileInfoSettings: [
        {
          type: 'text',
          label: 'Company Name',
          placeholder: `e.g. Darik`,
          description: 'Enter company name',
          variable: 'company_name',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'First Name',
          placeholder: `e.g. John`,
          description: 'Enter first name',
          variable: 'first_name',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Last Name',
          placeholder: `e.g. Doe`,
          description: 'Enter last name',
          variable: 'last_name',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Email',
          placeholder: `e.g. test@test.com`,
          description: 'Enter email address',
          variable: 'email',
          isEdit: false,
        },
      ],
      isSavePasswordButtonDisabled: true,
    }
  },

  async created() {
    this.fetchData()
    localStorage.removeItem('route-path')
  },

  computed: {
    getSendEmailURL() {
      return useEndpoints.marketplaceBuyer.resetBuyerEmail()
    },
    getVerifyEmailURL() {
      return useEndpoints.marketplaceBuyer.resetBuyerEmailVerify()
    },
  },

  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'profileInfo') {
        this.profileInfoSettings = this.profileInfoSettings.map((item, itemIndex) => {
          if (id === `profileInfo_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'profileInfo'
      }
      if (this.isPasswordEdit) {
          this.isPasswordEdit = false
          this.handlePasswordCancel()
        }
    })
  },


  methods: {
    async fetchData() {
      const profileInfoReq = this.$http.get(
        useEndpoints.marketplaceBuyer.profileIndex()
      )

      const requests = [profileInfoReq]
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            this.isLoaded = true
            const [profileInfoRes] = responses

            this.profileInfoDetails = profileInfoRes.data.data

            this.profileInfo.company_name =
              profileInfoRes.data.data.company_name
            this.profileInfo.first_name =
              profileInfoRes.data.data.user.first_name
            this.profileInfo.last_name = profileInfoRes.data.data.user.last_name
            this.profileInfo.email = profileInfoRes.data.data.user.email
            this.currentProfileEmail = profileInfoRes.data.data.user.email

            console.log('profile-info', this.profileInfo)
          })
        )
        .catch((err) => {
          console.warn({ err })
          this.$toast('Failed to retrieve data. Please try again.', {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
    },

    async onSave(key, val) {
      let data = new FormData()
      data.append(key, val)
      this.$http
        .patch(useEndpoints.marketplaceBuyer.updateBuyerProfile(), data)
        .then(() => {
          this.profileInfoSettings = this.profileInfoSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Update Successfully',
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },

    onEmail() {
      if (this.currentProfileEmail !== this.profileInfo.email) {
        this.$modal.show(`email-change`)
      }
    },

    handlePassEdit() {
      this.isPasswordEdit = !this.isPasswordEdit;
          this.profileInfoSettings = this.profileInfoSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
    },

    handlePasswordCancel() {
      this.form.old_password = null;
      this.form.new_password = null;
      this.repeat_new_password = null;
      this.isPasswordEdit = false;
    },

    async handleSave() {
      if (
        this.form.new_password !== null &&
        this.form.old_password !== null &&
        this.repeat_new_password !== null
      ) {
        if (this.repeat_new_password !== this.form.new_password) {
          this.$toast('Password is not match', {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.WARNING,
          })
        } else {
          this.isChangePasswordLoading = true
          const url = useEndpoints.marketplaceBuyer.changeBuyerPassword()
          let data = new xMan(this.form).toFormData()
          await this.$http
            .post(url, data)
            .then(() => {
              this.$toast('Password has been changed successfully', {
                position: POSITION.BOTTOM_RIGHT,
                type: TYPE.SUCCESS,
              })
            })
            .catch((err) => {
              console.log('handleSave', { err })
              this.$toast('Failed to change password.', {
                position: POSITION.BOTTOM_RIGHT,
                type: TYPE.ERROR,
              })
            })
            .finally(() => {
              this.isChangePasswordLoading = false
              this.form.new_password = null
              this.form.old_password = null
              this.repeat_new_password = null
            })
        }
        console.log(
          'Hello-t',
          this.form.new_password,
          this.form.old_password,
          this.repeat_new_password
        )
      } else {
        console.log(
          'Hello-f',
          this.form.new_password,
          this.form.old_password,
          this.repeat_new_password
        )
        this.$toast('All field is required', {
          position: POSITION.BOTTOM_RIGHT,
          type: TYPE.ERROR,
        })
      }
    },

    // Method to check if all password fields are filled in
    checkPasswordFields() {
      this.isSavePasswordButtonDisabled =
        !this.form.new_password ||
        !this.form.old_password ||
        !this.repeat_new_password;
    },
  },

  // Watchers to call the checkPasswordFields method whenever any password field changes
  watch: {
    'form.new_password': 'checkPasswordFields',
    'form.old_password': 'checkPasswordFields',
    'repeat_new_password': 'checkPasswordFields',
  },
}
</script>

<style lang="css">
.margin {
  margin-bottom: 40px;
}
.small-text{
  font-size: 13px;
}

</style>
