<template lang="">
  <div>
    <!-- payment-add-start -->
    <h2 class="font-bold text-2xl mt-10 mb-5">Payment Information</h2>
    <div
      class="relative border border-gray-200 rounded-md p-2 "
      :class="isLoading ? 'payment-box' : ''"
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        class="bg-white"
      />
      <div v-if="haveAnyPaymethodCreated" class="flex justify-between">
        <div class="w-11/12">
          <template v-for="(item, index) in paymentMethodsDetails">
            <div :key="`paymentMethod-${index}`">
              <div class="flex  gap-2">
                <div>
                  <i :class="getPaymentCardIconClass(item)" class="text-3xl" />
                </div>
                <div class=" ">
                  <p class="text-base font-bold">
                    {{ `Ending with ${item.detail}` }}
                  </p>
                  <p class="text-sm text-gray-500 font-bold">
                    Expired {{ getFormattedDateTime(item.expiry, 'MM/YY') }}
                  </p>
                  <p class="text-sm text-gray-500">
                    {{ item.cardholder_name
                    }}<span v-if="item.cardholder_email">, </span
                    >{{ item.cardholder_email }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div>
          <span
            class="text-gray-600 cursor-pointer"
            @click="deletePaymentMethod()"
            ><u>Delete</u></span
          >
        </div>
      </div>
      <div v-else class="flex justify-center lg:justify-start">
        <button
          class="relative bg-gray-300 rounded-md w-48 h-12   font-bold hover:bg-gray-400"
          @click="addPaymentCard()"
        >
          <loading
            :height="25"
            color="#000000"
            :active="isAddCardLoading"
            loader="bars"
            :class="`outline-none`"
            :is-full-page="false"
          />
          Add New Card
        </button>
      </div>
      <iframe ref="iframeRef" class="hidden" />
    </div>
    <!-- payment-add-end -->
  </div>
</template>
<script>
import { TYPE, POSITION } from 'vue-toastification'
import { getFormattedDateTime } from '@/utils/datetime'
import { useEndpoints } from '@/composables'
import { MarketplaceBuyerBillingInfoConfig } from '@/config/MarketplaceBuyerBillingInfoConfig'
export default {
  name: 'PaymentSettings',
  data: () => ({
    isLoading: false,
    isAddCardLoading: false,
    paymentMethodsDetails: [],
  }),
  async created() {
    this.fetchData()
  },
  computed: {
    haveAnyPaymethodCreated() {
      return this.paymentMethodsDetails.length > 0
    },
  },
  mounted() {
    const routeArray = this.$router.currentRoute.path.split('/')
    console.log('routeArray', routeArray)
    if (
      routeArray.length === 3 &&
      routeArray.includes('settings') &&
      routeArray.includes('payment')
    ) {
      localStorage.setItem('route-path', this.$router.currentRoute.path)
    }
    if (localStorage.getItem('route-path') !== null) {
      this.$router.push(localStorage.getItem('route-path'))
    }
  },
  methods: {
    getFormattedDateTime,
    async fetchData() {
      this.isLoading = true
      const paymentMethodsReq = this.$http.get(
        useEndpoints.marketplaceBuyer.paymentMethods()
      )
      const requests = [paymentMethodsReq]

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [paymentMethodsRes] = responses

            this.paymentMethodsDetails = paymentMethodsRes.data
          })
        )
        .catch((err) => {
          console.warn({ err })
          this.$toast('Failed to retrieve data. Please try again.', {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async addPaymentCard() {
      this.isAddCardLoading = true
      await this.$http
        .post(MarketplaceBuyerBillingInfoConfig.api.addPaymentMethod())
        .then((res) => {
          const ref = this.$refs.iframeRef
          ref.contentWindow.document.open()
          ref.contentWindow.document.write(res.data)
          this.isAddCardLoading = false
        })
        .finally(() => (this.isLoading = false))
    },
    async deletePaymentMethod() {
      this.isLoading = true

      await this.$http
        .delete(
          useEndpoints.marketplaceBuyer.deletePaymentMethods(
            this.paymentMethodsDetails[0].id
          )
        )
        .then((res) => {
          console.log('deleted-success', res.data)
          this.fetchData()
          this.$toast(`Payment Method has been deleted`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.SUCCESS,
          })
        })
        .catch((err) => {
          console.log('deleted-err', err)
          this.$toast(`${err}`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
  },
}
</script>
<style lang="scss">
.payment-box {
  min-height: 96px;
}
</style>
